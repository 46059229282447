<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between">
        <b-card-title>Permissions - {{ rolUpdated }}</b-card-title>
        <GoBack class="mr-2" />
      </div>
      <b-card-sub-title>Permit according to {{ rolUpdated }}</b-card-sub-title>
    </b-card-body>
    <!-- <div>
      <ul class="list-group border-0">
        <li
          v-for="(module, index) in modules"
          :key="index"
          class="list-group-item border-0"
        >
          <b-form-checkbox
            v-model="module.selected"
            @change="toggleModule(module)"
          >
            {{ module.subject }}
          </b-form-checkbox>
          <ul class="list-group border-0">
            <li
              v-for="(submodule, sIndex) in module.submodules"
              :key="sIndex"
              class="list-group-item border-0"
            >
              <b-form-checkbox
                v-model="submodule.selected"
                @change="toggleSubmodule(module)"
              >
                {{ submodule.subject }}
              </b-form-checkbox>
              <ul class="list-group border-0">
                <li
                  v-for="(action, aIndex) in submodule.actions"
                  :key="aIndex"
                  class="list-group-item border-0"
                >
                  <b-form-checkbox
                    v-model="action.selected"
                    @change="toggleItem(module)"
                  >
                    {{ action.action }}
                  </b-form-checkbox>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div> -->
    <b-table
      striped
      responsive
      bordered
      hover
      no-border-collapse
      sticky-header="60vh"
      :items="permissions"
      :fields="fields"
      class="mb-0"
    >
      <template #cell(modulo)="data">
        {{ data.value }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          :disabled="data.value[0] == null"
          :checked="data.value[0]"
          @change="changePermission(data.value)"
        />
      </template>
    </b-table>
    <b-button variant="primary" @click="saveChanges"
      ><span>Save Changes</span></b-button
    >
    <ToastNotification ref="toast" />
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import GoBack from "@/components/ButtonBack.vue";
import ToastNotification from "@/components/ToastNotification";

import axiosR from "@/services/admin/roles";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    ToastNotification,
    GoBack,
  },
  data() {
    return {
      permissionsSend: [],
      permissions: [],
      rolUpdated: null,
      fields: [
        { key: "modulo", label: "Module", sortable: false },
        { key: "action", label: "Action Custom", sortable: true },
        { key: "index", label: "View", sortable: true },
        { key: "store", label: "Create", sortable: true },
        { key: "show", label: "View One", sortable: true },
        { key: "update", label: "Update", sortable: true },
        { key: "destroy", label: "Delete", sortable: true },
        { key: "filter", label: "Filter", sortable: true },
      ],
      modules: [
        {
          subject: "module_patient",
          selected: false,
          submodules: [
            {
              subject: "patient",
              selected: false,
              actions: [
                { action: "index", selected: false },
                { action: "store", selected: false },
                { action: "show", selected: false },
                { action: "update", selected: false },
                { action: "destroy", selected: false },
              ],
            },
            {
              subject: "Office",
              selected: false,
              actions: [
                { action: "index", selected: false },
                { action: "store", selected: false },
                { action: "show", selected: false },
                { action: "update", selected: false },
                { action: "destroy", selected: false },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getPermissions();
  },
  methods: {
    toggleModule(module) {
      module.submodules.forEach((submodule) => {
        submodule.selected = module.selected;
        submodule.actions.forEach((action) => {
          action.selected = submodule.selected;
        });
      });
    },
    toggleSubmodule(module) {
      //poner el estado del modulo en true si algun submodulos estan en true
      //poner el estado de las acciones en true si el submodulo esta en true, solo poner en true si el submodulo esta en true
      const stateTree = module.submodules.some((submodule) => {
        return submodule.selected;
      });
      module.selected = stateTree;
      module.submodules.forEach((submodule) => {
        if (submodule.selected) {
          submodule.actions.forEach((action) => {
            action.selected = submodule.selected;
          });
        }
      });
    },
    toggleItem(module) {
      const stateTree = module.submodules.some((submodule) => {
        return submodule.actions.some((action) => {
          return action.selected;
        });
      });
      module.selected = stateTree;
      module.submodules.forEach((submodule) => {
        submodule.selected = stateTree;
      });
    },
    getPermissions() {
      axiosR
        .permissionsDetail(this.$route.params.id)
        .then(({ registro: { permissions, rol } }) => {
          this.rolUpdated = rol;
          const permisosFull = [];
          for (let permiso in permissions) {
            permisosFull.push(permissions[permiso]);
          }
          const permisoV2 = [];
          permisosFull.map((item) => {
            for (let permiso in item) {
              if (item[permiso].length == 2 && item[permiso][0] == true) {
                permisoV2.push(item[permiso][1]);
              }
            }
          });
          this.permissionsSend = permisoV2;
          this.permissions = permisosFull;
        });
    },
    changePermission(item) {
      const id = item[1];
      const index = this.permissionsSend.indexOf(id);

      if (index == -1) {
        this.permissionsSend.push(id);
      } else {
        this.permissionsSend.splice(index, 1);
      }
    },
    saveChanges() {
      const data = {
        role_id: this.$route.params.id,
        permissions: this.permissionsSend,
      };
      axiosR.assignPermission(data).then(({ registro: { permissions } }) => {
        this.$refs.toast.success("Permissions updated");
        this.getPermissions();
      });
    },
  },
};
</script>

<style></style>
